<template>
  <b-modal
    ref="modal-import-excel"
    :title="'Import excel'"
    ok-title="Import"
    scrollable
    @hidden="$emit('hidden')"
    @ok.prevent="saveExcel"
  >
    <b-row>
      <b-col cols="12">
        <b-form-group label="File">
          <b-form-file
            v-model="file"
            placeholder="Choose an excel..."
            drop-placeholder="Choose an excel..."
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import modalMixin from '@/mixins/modal'

import marketingLettersService from '@/views/correspondence/views/marketing-letters/service/marketing-letters.service'

export default {
  mixins: [modalMixin],
  props: {
    getAgentType: null,
  },
  data() {
    return {
      file: null,
    }
  },

  mounted() {
    this.toggleModal('modal-import-excel')
  },
  methods: {
    async saveExcel() {
      try {
        this.addPreloader()
        const formData = new FormData()

        formData.append('file', this.file)
        formData.append('type_agent', this.getAgentType)

        await marketingLettersService.importMarketingLettersExcel(formData)
        this.$emit('refresh')
        this.showSuccessSwal()

        this.removePreloader()
      } catch (error) {
        this.showErrorSwal(error)
        this.removePreloader()
      }
    },
  },
}
</script>

<style>
</style>
