<template>
  <div>
    <b-row
      ><b-col lg="10">
        <b-nav card-header pills class="m-0">
          <b-nav-item
            :to="{ name: $route.meta.pendingTab }"
            exact
            exact-active-class="sub-tab-nav"
            :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
          >
            PENDING
          </b-nav-item>
          <b-nav-item
            :to="{ name: $route.meta.sentTab }"
            exact
            exact-active-class="sub-tab-nav"
            :link-classes="['sub-tab-nav', 'px-3', bgTabsNavs]"
          >
            SENT
          </b-nav-item>
        </b-nav></b-col
      >
      <b-col>
        <b-button
          v-if="getRoute && currentUser.role_id == 1"
          variant="info"
          class="
            float-right
            nav-link
            active
            router-link-active
            px-3
            nav-dark-tabs
          "
          @click="modalImportExcel = true"
        >
          Import Excel
        </b-button>
        <b-button
          v-else-if="!getRoute"
          variant="success"
          class="
            float-right
            nav-link
            active
            router-link-active
            px-3
            nav-dark-tabs
          "
          @click="exportExcel"
        >
          Export Excel
        </b-button>
      </b-col>
    </b-row>
    <modal-import-from-excel-correspondence
      v-if="modalImportExcel"
      :get-agent-type="getAgentType"
      @hidden="modalImportExcel = false"
      @refresh="refresh"
    />
    <template class="border-top-info border-3 border-table-radius px-0">
      <router-view :key="contador" />
    </template>
  </div>
</template>
<script>
import moment from "moment";
import ModalImportFromExcelCorrespondence from "@/views/correspondence/views/marketing-letters/view/components/ModalImportFromExcelCorrespondence.vue";

import MarketingLettersService from "@/views/correspondence/views/marketing-letters/service/marketing-letters.service";
import { mapGetters } from "vuex";

export default {
  components: { ModalImportFromExcelCorrespondence },
  data() {
    return {
      modalImportExcel: false,
      contador: 0,
    };
  },
  computed: {
    getAgentType() {
      return this.$route.meta.pendingTab ===
        "correspondence-marketing-letters-corporate-agent-pending"
        ? 4
        : this.$route.meta.pendingTab === "correspondence-marketing-letters-keybook-pending"
        ? 5
        : 6;
    },

    getAgentTypeSent() {
      return this.$route.name ===
        "correspondence-marketing-letters-keybook-sent"
        ? 5
        : this.$route.name === "correspondence-marketing-letters-corporate-agent-sent"
        ? 4
        : 6;
    },
    getAgentTypeName() {
      return this.$route.name ===
        "correspondence-marketing-letters-keybook-sent"
        ? "KeyBook"
        : this.$route.name === "correspondence-marketing-letters-corporate-agent-sent"
        ? "Corporate Agent"
        : "Court Info";
    },
    getRoute() {
      return !(
        this.$route.name === "correspondence-marketing-letters-keybook-sent" ||
        this.$route.name ===
          "correspondence-marketing-letters-corporate-agent-sent" ||
        this.$route.name === "correspondence-marketing-letters-court-info-sent"
      );
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async exportExcel() {
      this.addPreloader();
      const params = {
        type_correspondence: this.getAgentTypeSent,
      };
      const data = await MarketingLettersService.exportMarketingLettersExcel(
        params
      );
      if (data.size != 0) {
        const namefile = `${this.getAgentTypeName}_${moment().format(
          "YYYY/MM/DD"
        )}`;
        await this.forceFileDownload(data, `${namefile}.xlsx`);
        this.contador++;
      } else {
        this.showToast(
          "info",
          "top-right",
          "Oops!",
          "XIcon",
          "NO DATA TO EXPORT"
        );
      }
      this.removePreloader();
    },
    refresh() {
      this.modalImportExcel = false;
      this.contador++;
    },
  },
};
</script>

<style scoped></style>
